/* Проверка переменной является ли она массивом */
function is_array(object){
	if (Object.prototype.toString.call(object) === '[object Array]')
		return true;
	return false;
}

function confirm_region_popover_box(obj){
	$('div.popover').each(function () {
		$(this).prev().popover('destroy');
	});
		
	var popoverContent = $('<h4>Угадали Ваш регион?</h4><br/><button type="button" id="region-detect-ok" class="btn btn-default">Да</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button type="button" id="region-detect-wrong" class="btn btn-default">Нет</button>');
	obj.popover({
		html: true,
		placement: 'bottom',
		trigger: 'focus',
		content: popoverContent
	});
	obj.popover('toggle');
	
	$('#region-detect-ok').click(function(){
		obj.popover('destroy');
		var href = "/?region_user_confirm=1";
		$.ajax({
			method: 'GET',
			url: href,
			success: function (data) {
				if (data.redirect.length > 0)
					document.location = data.redirect;
			}
		});
	});
	
	$('#region-detect-wrong').click(function(){
		obj.popover('destroy');
		$('#select-region').modal();
	});
	
	return false;
}

var addVKReachGoalEvent = function(variant_id) {
	var _tmr = _tmr || [];
	_tmr.push({ 
		type: 'reachGoal', 
		id: 3139350, 
		value: 0.0075, 
		goal: 'vk_ecom_cart', 
		params: { 
			product_id: variant_id
		}
	});
}

//Код на JavaScript для замены значения параметра "variant" в текущем URL на другое значение без перезагрузки страницы с использованием History API
function replaceQueryParam(key, value) {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  const newUrl = url.toString();

  history.pushState({ path: newUrl }, '', newUrl);
}