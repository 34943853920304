/* Функция для загрузки ветки дерева */
/* element - куда выводить, parent_id - какую категорию вывести */
function print_lazy_result(element, parent_id, icon_col, icon_spin, path, select_category_id){
	if (element == undefined)
	{
		if (icon_spin != undefined)
			icon_spin.hide();
		if (icon_col != undefined)
			icon_col.show();
		return false;
	}
		
	if (parent_id == undefined)
		parent_id = 0;
	var result = [];
	var href = mc_main_module_url + '?lazy_load=1&parent_id=' + parent_id;
	
	if (element.find('ol:first').length > 0)
	{
		if (icon_spin != undefined)
			icon_spin.hide();
		if (icon_col != undefined)
			icon_col.show();
			
		if (path != undefined && path.length > 0)
		{
			id = path[0];
			var _li = $('#categories li[data-id='+id+']');
			var _icon_col = _li.find('i.fa-minus:first');
			var _icon_exp = _li.find('i.fa-plus:first');
			var _icon_spin = _li.find('i.fa-spinner:first');
			
			var ol = _li.find('ol:first');
			
			_icon_exp.hide();
			_icon_spin.show();
			_li.removeClass('collapsed');
			ol.show();
			
			path.shift();
			print_lazy_result(_li, id, _icon_col, _icon_spin, path, select_category_id!=undefined?select_category_id:undefined);
		}
		else
			if (select_category_id != undefined)
			{
				$('#categories li[data-id='+select_category_id+'] a:first').addClass('active');
				$('#categories li[data-id='+select_category_id+'] i.fa-plus:first').trigger('click');
			}
	}
	else
		$.get(href, function(data){
			if (data == undefined || data.length == 0)
				return false;
			var ol = $('<ol></ol>');
						
			for(var i=0 ; i<data.length ; i++){
				var li = $('<li class="collapsed'+(data[i].folder?' havechild':'')+'" data-id="'+data[i].id+'" data-level="'+(parseInt(element.attr('data-level'))+1)+'"></li>');
				if (data[i].folder){
					li.append('<i class="fa fa-minus" style="display: none;"></i>');
					li.append('<i class="fa fa-plus" style="display: block;"></i>');
					li.append('<i class="fa fa-spin fa fa-spinner" style="display: none;"></i>');
				}
				li.append('<div><span>' + data[i].products_count + '</span><a href="' + mc_products_module_url + data[i].url + '" class="menu' + data[i].id + ' menu-level' + (parseInt(element.attr('data-level')) + 1) + '">' + data[i].title + '</a></div>');
				ol.append(li);
			}
			element.append(ol);
			if (icon_spin != undefined)
				icon_spin.hide();
			if (icon_col != undefined)
				icon_col.show();
				
			if (path != undefined && path.length > 0)
			{
				id = path[0];
				var _li = $('#categories li[data-id='+id+']');
				var _icon_col = _li.find('i.fa-minus:first');
				var _icon_exp = _li.find('i.fa-plus:first');
				var _icon_spin = _li.find('i.fa-spinner:first');
				
				_icon_exp.hide();
				_icon_spin.show();
				_li.removeClass('collapsed');
				
				path.shift();
				print_lazy_result(_li, id, _icon_col, _icon_spin, path, select_category_id!=undefined?select_category_id:undefined);
			}
			else
				if (select_category_id != undefined)
				{
					$('#categories li[data-id='+select_category_id+'] a:first').addClass('active');
					$('#categories li[data-id='+select_category_id+'] i.fa-plus').trigger('click');
				}
		});
}


/* AJAX загрузка списка просмотренных товаров */
function history_products_ajax(){
	$.ajax({
		type: 'GET',
		url: mc_history_module_url,
		success: function(data) {
			$('#viewedproducts div.container').html(data);
			
			/*$('a[data-toggle="tooltip"]').tooltip({
				container: 'body'
			});*/
		}
	});
	return false;
}

function apply_products_filter(){
	var params = [];
	$('#filter_spinner').show();
	var scroll_to_up = false;//(scroll_up == undefined || scroll_up == true) ? true : false;
	params.push({
		'key': 'url',
		'value': $('div#filter').attr('data-url')
	});
	$('div#filter select').each(function(){
		if ($(this).val().length != 0 && parseInt($(this).val()) != 0)
			params.push({
				'key': $(this).attr('name'),
				'value': $(this).val()
			});
	});
	/*$('div#filter input:checked:visible').each(function()/*/
	$('div#filter input:checked').each(function(){
		if ($(this).val().length != 0 && parseInt($(this).val()) != 0)
			params.push({
				'key': $(this).attr('name'),
				'value': $(this).val()
			});
	});
	$('div#filter input[data-control=rangeSlider]').each(function(){
		if ($(this).val().length != 0 && (
			($(this).attr('data-from').length != 0 && $(this).attr('data-to').length != 0 && $(this).val() != ($(this).attr('data-from')+';'+$(this).attr('data-to'))) ||
			$(this).attr('data-property-filtered') == "1"))
			params.push({
				'key': $(this).attr('name'),
				'value': $(this).val()
			});
			
	});
	products_request_ajax(params, undefined, undefined, true, scroll_to_up, false);
	return false;
}

/* Копия этой функции в файле actions_functions.js*/
/*
function rangeSliderStart(){
	$('input[data-control=rangeSlider]').rangeSlider({
		onChange: function(obj){        // function-callback, is called on every change
			//console.log('content_functions rangeSlider ' + obj.input.attr('name') + ' value=' + obj.input.val());
			if (!obj.input.data("disable"))
			{
				var i1 = $('#' + obj.input.attr('data-id') + "_min");
				var i2 = $('#' + obj.input.attr('data-id') + "_max");
			
				value_array = obj.input.val().split(";");
				i1.val(value_array[0]);
				i2.val(value_array[1]);
			}
		},
		onFinish: function(obj){
			//$('#apply_products_filter').trigger('click',false);
            if ($(obj.input).closest('#main-container').length) {
                apply_products_filter();
            }
		}
	});
}
*/

var products_ajax_context;
/* AJAX-загрузка списка товаров 
		params - параметры для отбора
		push_history - флаг добавлять ли в историю
		jump_if_one - флаг переходить ли к product_request_ajax если найдется всего 1 товар
		animate_scroll - плавная прокрутка страницы вверх
		scroll_up - прокручивать ли наверх
		open_category_in_menu - открывать ли в левом меню текущую категорию (при изменении фильтра например не надо)
		append_mode - режим работы кнопки "Показать еще", когда в список товаров добавляются товары
*/
function products_request_ajax(params, push_history, jump_if_one, animate_scroll, scroll_up, open_category_in_menu, append_mode){
	var url = mc_products_module_url;
	var show_products_count_only = false;
	var scroll_to_up = (scroll_up == undefined || scroll_up == true) ? true : false;
	var filter_is_active = $('#filter').hasClass('active');
	var filter_mob_present = $('#fil_mob #filter').length > 0 ? true : false;
	if (params != undefined && params.length>0)
	{
		url += "?";
		for(var index in params)
		{
			if (params[index].key == "url")
			{
				url = params[index].value;
				if (params.length > 1)
					url += "?";
				break;
			}
		}
		
		var index2 = 0;
		for(var index in params)
		{
			if (params[index].key == "format" && params[index].value == "products_count")
				show_products_count_only = true;
		
			if (params[index].key != "url")
			{
				if (is_array(params[index].value))
				{
					for(var idx in params[index].value)
					{
						if (index2 > 0)
							url += "&";
						url += params[index].key + "=" + params[index].value[idx];
						index2 += 1;
					}
				}
				else
				{
					if (index2 > 0)
						url += "&";
					url += params[index].key + "=" + params[index].value;
					index2 += 1;
				}
			}
		}
	}
	var full_url = url;
	if (params == undefined || params.length == 0 || (params.length == 1 && params[0].key == 'url' && params[0].value.indexOf('?')==-1))
		full_url += "?";
	if (params != undefined && params.length > 0 && !(params.length == 1 && params[0].key == 'url' && params[0].value.indexOf('?')==-1))
		full_url += "&";
	full_url += "ajax=1";
	
	if (!show_products_count_only)
	{
		if (scroll_to_up)
		{
			if (animate_scroll == undefined)
			{
				if ($('#h1-sort-header-block h1').length > 0)
				{
					$("body,html").animate({
						scrollTop: $('#h1-sort-header-block h1').next().offset().top
					}, 0);
				}
				else
				{
					$("body,html").animate({
						scrollTop: $('#main-right-container').offset().top
					}, 0);
				}
			}
			else
				$("body,html").animate({
					scrollTop: $('#main-right-container').offset().top
				}, 200);
		}
	
		/*$('a[data-toggle="tooltip"]').each(function(){
			$(this).tooltip('hide');
		});*/
		
		$('ul.horizontal-menu li.active').removeClass('active');

		$('#brands a.active').removeClass('active');
		
		$('div.tech-menu.tech-menu--opened').removeClass('tech-menu--opened');
		
		if (append_mode == undefined || append_mode == false)
		{
			$('#main-right-container').fadeOut(125, function(){
				$('#main-right-container').html('<div class="loading"><div class="loader"></div></div>').show();
			});
		}
	}
	
	if (products_ajax_context != null)
		products_ajax_context.abort();
	
	$(".filterbox .nicescroll").each(function(){
		var $checkbox = $(this).find(":checkbox:checked:first").closest(".checkbox");
		if ($checkbox.length)
		{
			if (jQuery().niceScroll && $(this).getNiceScroll(0)) var pos = $(this).getNiceScroll(0).getScrollTop();
			else pos = 0;
			var id = $(this).closest(".filterbox").attr("id");
			$("body").data("scrollto_id_"+id+"_pos", pos);
		}
	});
	
	products_ajax_context = $.ajax({
		type: 'GET',
		url: full_url,
		success: function(data) {
			
			if (data.redirect_url != undefined && data.redirect_url.length > 0)
			{
				document.location.href = data.redirect_url;
				return false;
			}
		
			/*if (show_products_count_only)
			{
				$('#apply_products_filter').html('Показать ' + data.data + ' товаров');
				return false;
			}*/

			//$("#filter").insertAfter("#searchcategories");
			
			$('#filter_spinner').hide();
		
			if (window['yaCounter' + mc_yaCounterNumber]) {
				window['yaCounter' + mc_yaCounterNumber].hit(url, data.meta_title, null);
			}
		
			if ($('#searchField').val().length == 0)
			{
				$('#refreshpart').hide();
				$('#searchCancelButton').hide();
			}
			if (data.products_categories == undefined)
				$('#searchcategories').hide();
		
			if (jump_if_one != undefined && jump_if_one && data.products_count == 1 && data.product_url.length > 0)
			{
				$('#searchcategories').hide();
				product_request_ajax(data.product_url, push_history);
				return false;
			}
		
			if (data.meta_title)
				document.title = data.meta_title;
			if (data.meta_keywords)
				$('meta[name=keywords]').prop('content', data.meta_keywords);
			if (data.meta_description)
				$('meta[name=description]').prop('content', data.meta_description);
			
			$('#breadcrumbs').html(data.breadcrumbs);
			if (data.breadcrumbs && data.breadcrumbs.length > 0)
				$('#breadcrumbs').removeClass('hidden');
			else
				$('#breadcrumbs').addClass('hidden');
			
			$('#products-header-update-part').html(data.h1_sort_header_update_block);
			
			$('#category-banners').html(data.category_banners);
			
			if (data.filter != undefined)
			{
				// if (filter_mob_present) {
				// 	$('#fil_mob').html(data.filter);
				// } else {
				// 	$('#filter').replaceWith(data.filter);
				// 	if (filter_is_active) {
				// 		$('#filter').addClass('active');
				// 	}
				// }
                $('#filter').replaceWith(data.filter);
				rangeSliderStart();
                filter_update();
			}

			/*$('a[data-toggle="tooltip"]').each(function(){
				$(this).tooltip('hide');
			});*/
		
			if (typeof history.pushState != undefined && (push_history == undefined || push_history == true) && !show_products_count_only) {
				history.pushState({ mode: 'products', params: params, selected_category_id: data.category_id, category_path: data.category_path},null,encodeURI(decodeURI(url)));
			}
			
			if (append_mode != undefined && append_mode == true){
				if (mc_mode.length == 0 || mc_mode == 'list') {
					$(data.data).insertAfter($('#products-update-block ul.list:last'));
				} else {
					$(data.data).insertAfter($('#products-update-block div.plitka:last'));
				}
				$('#main-right-container div.loading').remove();
			}
			else
			{
				$('#main-right-container').hide().html(data.data);
			}
				
			$('body').removeClass();
			$('body').addClass(data.body_category_css);
			
			//$('#main-left-container').show();
			if ($('#main-right-container').hasClass('col-xs-12'))
			{
				$('#main-right-container').removeClass('col-xs-12');
				$('#main-right-container').addClass('col-xs-9');
			}
			
			/* Раскроем нужную категорию */
			if (data.category_path != undefined && (open_category_in_menu == undefined || open_category_in_menu == true))
			{
				$('#categories a.active').removeClass('active');
				$('#categories li:not(.collapsed) ' + mc_nested_level).each(function(){
					var icon_minus = $(this).find('i.fa-minus');
					if (icon_minus.length > 0)
						icon_minus.trigger('click');
				});
				if (data.category_path.length > 0)
				{
					var path = data.category_path.split(',');
					print_lazy_result($('#categories'),0,undefined,undefined, path, data.category_id);
				}
				else
					print_lazy_result($('#categories'),0,undefined,undefined, '', data.category_id);
			}
			/* Раскроем нужную категорию(End) */
			
			/*Выводим категории поиска*/
			if (data.products_categories != undefined)
			{
				$('#searchcategories').html('<div class="searchcats">Категории:</div>');
				var ol = $('<ol></ol>');
				for(var index in data.products_categories)
				{
					var position_array = data.products_categories[index];
					for(var index2 in position_array)
					{
						var category = position_array[index2];
						ol.append('<li><div><span>'+category.count+'</span><a class="menu61'+((category.id==data.category_id)?' active':'')+'" href="'+category.url+'">'+category.name+'</a></div></li>');
					}
				}
				$('#searchcategories').append(ol);
				$('#searchcategories').show();
			}
			
			if (data.finded_categories != undefined)
			{
				$('#searchcategories').html(data.finded_categories);
				if (data.finded_categories.length > 0)
					$('#searchcategories').show();
				else
					$('#searchcategories').hide();
			}

			/*$('a[data-toggle="tooltip"]').tooltip({
				container: 'body'
			});*/

			$('a[data-type="qtip"]').qtip({
				content: {
					attr: 'data-content',
					title: function(event, api) {
						return $(this).attr('data-title');
					},
					button: true
				},
				style: {
					classes: 'qtip-light qtip-rounded'
				},
				show: {
					delay: 0/*,
					event: 'hover'*/
				}/*,
				hide: {
					event: 'unfocus'
				}*/
			});
			
			$('.un_f1').height("280px");
			
			$('#main-right-container').stop().fadeIn(125);
			
			lazy_load();
			
			//responsive_update();
			
			products_ajax_context = null;
			
			if ($('body script[src$="elements/products.js"]').length == 0) {
				var s = document.createElement("script");
				s.type = "text/javascript";
				s.src = mc_path_frontend_template + "/js/elements/products_list.js";
				$("body").append(s);
				
				s = document.createElement("script");
				s.type = "text/javascript";
				s.src = mc_path_frontend_template + "/js/elements/products.js";
				$("body").append(s);
			}
			
			if ($('#fil_mob #filter').length) {
				//console.log("s");
			} else {
				if (!$('#fil_mob #filter').length) {
					//console.log("ns");
				}
			}
			
			if (typeof lazyLoadInstance !== 'undefined') {
				lazyLoadInstance.update();
			}
		}
	});
	return false;
}

/* AJAX-загрузка страницы бренда
		params - параметры для отбора
		push_history - флаг добавлять ли в историю
*/
function brand_request_ajax(params, push_history){
	var url = mc_brand_module_url;
	if (params != undefined && params.length>0)
	{
		url += "?";
		for(var index in params)
		{
			if (params[index].key == "url")
			{
				url = params[index].value;
				if (params.length > 1)
					url += "?";
				break;
			}
		}
		
		var index2 = 0;
		for(var index in params)
		{
			if (params[index].key != "url")
			{
				if (index2 > 0)
					url += "&";
				url += params[index].key + "=" + params[index].value;
				index2 += 1;
			}
		}
	}
	var full_url = url;

	document.location.href = url;
	return false;
}

function product_request_ajax(url, push_history){
	var full_url = url;
	
	document.location.href = url;
	return false;
	
	if (url.indexOf('?')==-1)
		full_url += "?";
	else
		full_url += "&";
	full_url += "ajax=1";
	
	/*$('a[data-toggle="tooltip"]').each(function(){
			$(this).tooltip('hide');
		});*/
		
	$('#preloader').fadeIn(100);

	$.ajax({
		type: 'GET',
		url: full_url,
		success: function(data) {
		
			$('ul.horizontal-menu li.active').removeClass('active');
			$('#searchcategories').hide();
			$('#brands a.active').removeClass('active');
			$('#filter').hide();
			$('div.tech-menu.tech-menu--opened').removeClass('tech-menu--opened');
			$('div.brands-grey').hide();
		
			//{if $current_domain->settings_yandex_metric_counter}yaCounter{$current_domain->settings_yandex_metric_counter}.hit(url, data.meta_title, null);{/if}
            if (window['yaCounter' + mc_yaCounterNumber]) {
				window['yaCounter' + mc_yaCounterNumber].hit(url, data.meta_title, null);
			}
		
			document.title = data.meta_title;
			$('meta[name=keywords]').prop('content', data.meta_keywords);
			$('meta[name=description]').prop('content', data.meta_description);
				
			/*$('a[data-toggle="tooltip"]').each(function(){
				$(this).tooltip('hide');
			});*/

			if (typeof history.pushState != undefined && (push_history == undefined || push_history == true)) {
				history.pushState({ mode: 'product', url: url, category_path: data.category_path, selected_category_id: data.category_id},null,encodeURI(decodeURI(url)));
			}
			$('#content').html(data.data);
			$('body').removeClass();
			$('body').addClass(data.body_category_css);
			$('body').addClass(data.body_product_css);
			
			$('#main-left-container').hide();
			if ($('#main-right-container').hasClass('col-xs-9'))
			{
				$('#main-right-container').removeClass('col-xs-9');
				$('#main-right-container').addClass('col-xs-12');
			}
			
			if (push_history == undefined || push_history == true)
			{
				/* Раскроем нужную категорию */
				$('#categories a.active').removeClass('active');
				$('#categories li:not(.collapsed) ' + mc_nested_level).each(function(){
					var icon_minus = $(this).find('i.fa-minus');
					if (icon_minus.length > 0)
						icon_minus.trigger('click');
				});
				
				if (data.category_path.length > 0)
				{
					var path = data.category_path.split(',');
					print_lazy_result($('#categories'),0,undefined,undefined, path, data.category_id);
				}
				else
					print_lazy_result($('#categories'),0,undefined,undefined, [], data.category_id);
					
				history_products_ajax();
			}
			$('a[data-type="qtip"]').qtip({
				content: {
					attr: 'data-content',
					title: function (event, api) {
						return $(this).attr('data-title');
					},
					button: true
				},
				style: {
					classes: 'qtip-light qtip-wiki'
				},
				show: {
					delay: 0/*,
					event: 'hover'*/
				}/*,
				hide: {
					event: 'unfocus'
				}*/
			});
			
			$('a[data-type="qtip2"]').qtip({
				content: {
					attr: 'data-content',
					title: function (event, api) {
						return $(this).attr('data-title');
					},
					button: true
				},
				style: {
					classes: 'qtip-light qtip-wiki'
				},
				show: {
					delay: 0,
					event: 'click'
				},
				hide: {
					event: 'unfocus'
				}
			});
			
			$('a[data-type="qtip2-mobile"]').qtip({
				content: {
					attr: 'data-content',
					title: function (event, api) {
						return $(this).attr('data-title');
					},
					button: true
				},
				style: {
					classes: 'qtip-light qtip-wiki'
				},
				show: {
					delay: 0,
					event: 'click'
				},
				hide: {
					event: 'unfocus'
				},
				position: {
					adjust: {
						y: -200
					}
				}
			});
			/*$('a[data-toggle="tooltip"]').tooltip({
				container: 'body'
			});*/
			
			$('#breadcrumbs').html(data.breadcrumbs);
			if (data.breadcrumbs && data.breadcrumbs.length > 0)
				$('#breadcrumbs').removeClass('hidden');
			else
				$('#breadcrumbs').addClass('hidden');
			$('#h1-sort-header-block').html('');
			$('#category-banners').html(data.category_banners);
			
			$('#preloader').stop().fadeOut(100, function(){
				$("body,html").animate({
					scrollTop:0
				}, 100);
			});
			
			/*
			if ($('body script[src$="elements/product.js"]').length == 0) {
				//var s = document.createElement("script");
				//s.type = "text/javascript";
				//s.src = mc_path_frontend_template + "/js/jcarousel.js";
				//$("body").append(s);
				$.getScript(mc_path_frontend_template + "/js/jcarousel.js");
				
				//s = document.createElement("script");
				//s.type = "text/javascript";
				//s.src = mc_path_frontend_template + "/js/elements/product.js";
				//$("body").append(s);
				$.getScript(mc_path_frontend_template + "/js/elements/product.js");
				
				//s = document.createElement("script");
				//s.type = "text/javascript";
				//s.src = mc_path_frontend_template + "/js/elements/related_products.js";
				//$("body").append(s);
				$.getScript(mc_path_frontend_template + "/js/elements/related_products.js");
			}
			*/
		}
	});
	return false;
}

function material_request_ajax(url, push_history){
	var full_url = url;
	if (full_url.indexOf('?') == -1)
		full_url += '?ajax';
	else
		full_url += '&ajax';
	$('ul.horizontal-menu li.active').removeClass('active');
	$('#brands a.active').removeClass('active');
	$('#searchcategories').hide();
	$('#filter').hide();
	$('div.tech-menu.tech-menu--opened').removeClass('tech-menu--opened');
	
	if (url == mc_config_root_url || url == (mc_config_root_url + '/'))
		document.location.href = url;
		
	$('#main-left-container').show();
	$('#main-right-container').removeClass('col-xs-12');
	$('#main-right-container').addClass('col-xs-9');
		
	$.ajax({
		type: 'GET',
		url: full_url,
		success: function(data) {
		
			//{if $current_domain->settings_yandex_metric_counter}yaCounter{$current_domain->settings_yandex_metric_counter}.hit(url, data.meta_title, null);{/if}
            if (window['yaCounter' + mc_yaCounterNumber]) {
				window['yaCounter' + mc_yaCounterNumber].hit(url, data.meta_title, null);
			}
		
			document.title = data.meta_title;
			$('meta[name=keywords]').prop('content', data.meta_keywords);
			$('meta[name=description]').prop('content', data.meta_description);

			if (typeof history.pushState != undefined && (push_history == undefined || push_history == true)) {
				/* Свернем категории */
				$('#categories a.active').removeClass('active');
				$('#categories li:not(.collapsed) ' + mc_nested_level).each(function(){
					var icon_minus = $(this).find('i.fa-minus');
					icon_minus.trigger('click');
				});
				
				history.pushState({ mode: 'material', url: url},null,encodeURI(decodeURI(url)));
			}
			
			$('#content').html(data.data);
			$('body').removeClass();
			$('body').addClass(data.body_category_css);
			$('body').addClass(data.body_material_css);
			
			$('#breadcrumbs').html(data.breadcrumbs);
			if (data.breadcrumbs && data.breadcrumbs.length > 0)
				$('#breadcrumbs').removeClass('hidden');
			else
				$('#breadcrumbs').addClass('hidden');
			$('#h1-sort-header-block').html('');
			$('#category-banners').html(data.category_banners);
			
			if ($('body script[src$="elements/material.js"]').length == 0) {
				var s = document.createElement("script");
				s.type = "text/javascript";
				s.src = mc_path_frontend_template + "/js/elements/material.js";
				$("body").append(s);
			}
		}
	});
	return false;
}

function cart_request_ajax(push_history){
	var url = mc_config_root_url + '/cart/';
	
	document.location.href = url;
	return false;
}

function compare_request_ajax(push_history){
	var url = $('#make-compare').attr('href');
	$('ul.horizontal-menu li.active').removeClass('active');
	$('#brands a.active').removeClass('active');
	$('#searchcategories').hide();
	$('div.tech-menu.tech-menu--opened').removeClass('tech-menu--opened');
	$.ajax({
		type: 'GET',
		url: url+'&ajax=1',
		success: function(data) {
			if (data.success)
			{
				//{if $current_domain->settings_yandex_metric_counter}yaCounter{$current_domain->settings_yandex_metric_counter}.hit(url, data.meta_title, null);{/if}
                if (window['yaCounter' + mc_yaCounterNumber]) {
					window['yaCounter' + mc_yaCounterNumber].hit(url, data.meta_title, null);
				}
			
				if (typeof history.pushState != undefined && (push_history == undefined || push_history == true)) { 
					history.pushState({ mode: 'compare', url: url},null,encodeURI(decodeURI(url)));
				}
				$('#main-left-container').hide();
				$('#main-right-container').removeClass('col-xs-9');
				$('#main-right-container').addClass('col-xs-12');
				$('#content').html(data.data);
				
				if ($('body script[src$="elements/compare.js"]').length == 0) {
					var s = document.createElement("script");
					s.type = "text/javascript";
					s.src = mc_path_frontend_template + "/js/elements/compare.js";
					$("body").append(s);
				}
			}
		}
	});
	return false;
}

function update_cart_info(){
	$.get(mc_cart_info_module_url, function(data){
		if (data.success)
			$('#cart').replaceWith(data.header);
	});
}

function update_external_module_forms() {
	var request_ext_module_form_url = '/cart/?request_ext_module_form=';
	$('#delivery-section div.delivery-select input[name=delivery_id]').each(function(){
		if ($(this).attr('data-ext-module-name').length > 0) {
			var delivery_select = $(this).closest('div.delivery-select');
			var delivery_id = $(this).val();
			$.get(request_ext_module_form_url + delivery_id, function(data){
				if (data.success)
					delivery_select.find('div.delivery-external-module-block').html(data.ext_module_form);
			});
		}
	});
}

var search_ajax_context;

/* запрос товаров на ajax'e для поиска*/
function search_request_ajax(keyword){
	if (keyword == undefined || keyword.length == 0 || keyword.length < mc_settings_search_min_length)
	{
		$('#refreshpart').html('').hide();
		return false;
	}

	$('#brands a.active').removeClass('active');
	
	if (search_ajax_context != null)
	{
		$('#search-ajax-loader').remove();
		search_ajax_context.abort();
	}
	else
	{
		$('<div id="search-ajax-loader"><i class="fa fa-spinner fa-spin fa-large"></i></div>').insertBefore('#refreshpart');
	}
	
	var url = mc_config_root_url + '/ajax/search.php?keyword=' + encodeURI(decodeURI(keyword.toLowerCase()));
	search_ajax_context = $.ajax({
		type: 'GET',
		url: url,
		success: function(data) {
			if (data.success)
			{
				$('#search-ajax-loader').remove();
				$('#refreshpart').html(data.data);
				$('#refreshpart').show();
				$('#searchCancelButton').show();
			}
			search_ajax_context = null;
		}
	});
	return false;
}

/* добавление поискового запроса в историю */
function add_to_search_history(keyword){
	var url = mc_products_module_url + '?ajax=1&format=add_to_history&keyword=' + encodeURI(decodeURI(keyword));
	$.ajax({
		type: 'GET',
		url: url
	});
	return false;
}
