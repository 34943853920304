$(document).ready(function () {
	$(".fancybox").fancybox({
		autoResize: true,
		autoCenter: true,
		prevEffect: 'none',
		nextEffect: 'none',
		helpers: {
			title: {
				type: 'outside'
			},
			thumbs: {
				width: 50,
				height: 50
			},
			overlay: {
				locked: false
			}
		}
	});
	
	/*var swiper = new Swiper('.swiper-container', {
		pagination: '.swiper-pagination',
		nextButton: '.swiper-button-next',
		prevButton: '.swiper-button-prev',
		paginationClickable: true,
		spaceBetween: 30,
		speed: 2000,
		centeredSlides: true,
		autoplay: 6000,
		autoplayDisableOnInteraction: false,
		loop:true
	});*/
	if ($('.main-slideshow').length > 0/*.is(':visible')*/) {
		var swiper = new Swiper('.main-slideshow', {
			slidesPerView: 1,
			spaceBetween: 0,
			//nextButton: '.swiper-button-next',
			//prevButton: '.swiper-button-prev',
			speed: 1000,
			//centeredSlides: true,
			autoplay: {
				delay: 4200,
			},
			autoplayDisableOnInteraction: false,
			loop: true,
			preventClicks: false,
			preventClicksPropagation: false,
            breakpoints: {
				0: {
					slidesPerView: 1
				},
                768: {
                    slidesPerView: 3
                }
            },
			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
	}
	/*if ($('.main-slideshow-mobile').is(':visible')) {
		var swiper = new Swiper('.main-slideshow-mobile', {
			slidesPerView: 1,
			spaceBetween: 0,
			nextButton: '.swiper-button-next',
			prevButton: '.swiper-button-prev',
			speed: 1000,
			autoplay: 6000,
			autoplayDisableOnInteraction: false,
			loop:true,
			preventClicks: false,
			preventClicksPropagation: false,
            breakpoints: {
                9999: {
                    slidesPerView: 2
                },
                539: {
                    slidesPerView: 1
                }
            }
		});
	}*/
});