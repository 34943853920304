$('.expand-more-tags').click(function(){
	$(this).prev('div.items-collapse.popular-tags').hide();
	$(this).next('div.items-collapse.all-tags').show();
	$(this).hide();
	return false;
});
$('#main-left-container').on('click', '.expand-more-tags2', function(){
	$(this).prev('div.items-collapse').find('div.hidden').toggleClass('hidden');
	$(this).hide();
	return false;
});
$('#fil_mob').on('click', '.expand-more-tags2', function(){
	$(this).prev('div.items-collapse').find('div.hidden').toggleClass('hidden');
	$(this).hide();
	return false;
});



function filter_update() {

    $('.navbar-offcanvas .filterbox, .modal-content .filterbox').each(function(){
        var $range_input = $(this).find('input[data-control="rangeSlider"]');
        if ($range_input.length) {
            var collapsed = $range_input.data('from') == $range_input.data('min') && $range_input.data('to') == $range_input.data('max');
            $(this).collapse((collapsed)?'hide':'show').toggleClass('in', !collapsed).closest('.form-group').toggleClass('form-group--collapsed', collapsed);
            // if ($range_input.data('from') != $range_input.data('min') || $range_input.data('to') != $range_input.data('max')) {
            //
            // }
            // console.log($range_input, $range_input.val(), $range_input.data('from'), $range_input.data('to'), $range_input.data('min'), $range_input.data('max'), $range_input.data('from') != $range_input.data('min') || $range_input.data('to') != $range_input.data('max'));
        }
        else if ($(this).closest('.form-group.checkboxes, .form-group.radio-filter').length) {
            var $checkboxes = $(this).find('input[type="checkbox"], input[type="radio"]');
            var $checkboxes_checked = $checkboxes.filter(':checked');
            var collapsed = !$checkboxes_checked.length;
            //console.log($(this).attr('id'), $checkboxes.length, $checkboxes_checked.length, collapsed, !collapsed);
            $(this).collapse((collapsed)?'hide':'show').toggleClass('in', !collapsed).closest('.form-group').toggleClass('form-group--collapsed', collapsed);
            $checkboxes.closest('.checkbox, .radio').removeClass('checked');
            $checkboxes_checked.closest('.checkbox, .radio').addClass('checked');
            //console.log($checkboxes.length, $checkboxes.filter(':checked').length, !$checkboxes.filter(':checked').length);
        }
    });
}

$(document).on("click", ".js-filter-update", function (e) {
    e.preventDefault();
    filter_update();
});

$(document).on("show.bs.modal", ".js-modal-filter-group", function (e) {
    var $from = $(e.relatedTarget).closest('.form-group').find('.filterbox__inner');
    var $to = $(e.target).find('.modal-content-inner');
    var $header = $(e.target).find('.modal-header-inner');
    $header.empty();
    $to.data('from', $from.parent());
    //console.log($from.length, $from[0], $to.length, $to[0]);
    var $label = $from.closest('.form-group').children('label');
    if ($label.length) {
        $header.html($label.text());
    }
    $to.empty();
    $from.appendTo($to);
});

$(document).on("hide.bs.modal", ".js-modal-filter-group", function (e) {
    var $to = $(e.target).find('.modal-content-inner').data('from');
    var $from = $(e.target).find('.modal-content-inner .filterbox__inner');
    //console.log($from.length, $from[0], $to.length, $to[0]);
    $to.empty();
    $from.appendTo($to);
});

$(document).on("shown.bs.collapse", ".filterbox.collapse", function (e) {
    //console.log(e);
    $(e.target).closest('.form-group').removeClass('form-group--collapsed');
});

$(document).on("hidden.bs.collapse", ".filterbox.collapse", function (e) {
    //console.log(e);
    $(e.target).closest('.form-group').addClass('form-group--collapsed');
});