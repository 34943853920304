var lazyLoadInstance;

$(document).ready(function () {
	
	lazyLoadInstance = new LazyLoad({
		elements_selector: ".lazy",
		callback_loaded: function(el) {
			el.classList.remove('lazy');
			el.classList.remove('loader');
		}
		// ... more custom settings?
	});
	
	/* Подключаем скрипт кнопки Вверх */
	$(window).scroll(function () {
		if ($(this).scrollTop() > 0)
			$('#scroller').fadeIn();
		else
			$('#scroller').fadeOut();
	});
	$('#scroller').click(function () {
		$('body,html').animate({ scrollTop: 0 }, 400);
		return false;
	});
	/* /Подключаем скрипт кнопки Вверх */
	
	$('a[data-toggle="tooltip"]').tooltip({
		container: 'body'
	});

	$('a[data-type="qtip"]').qtip({
		content: {
			attr: 'data-content',
			title: function (event, api) {
				return $(this).attr('data-title');
			},
			button: true
		},
		style: {
			classes: 'qtip-light qtip-wiki'
		},
		show: {
			delay: 0/*,
			event: 'hover'*/
		}
	});
	
	$('a[data-type="qtip2"]').qtip({
		content: {
			attr: 'data-content',
			title: function (event, api) {
				return $(this).attr('data-title');
			},
			button: true
		},
		style: {
			classes: 'qtip-light qtip-wiki'
		},
		show: {
			delay: 0,
			event: 'click'
		},
		hide: {
			event: 'unfocus'
		}
	});
	
	$('a[data-type="qtip2-mobile"]').qtip({
		content: {
			attr: 'data-content',
			title: function (event, api) {
				return $(this).attr('data-title');
			},
			button: true
		},
		style: {
			classes: 'qtip-light qtip-wiki'
		},
		show: {
			delay: 0,
			event: 'click'
		},
		hide: {
			event: 'unfocus'
		},
		position: {
			my: 'bottom center',  // Position my top left...
			at: 'top center', // at the bottom right of...
			target: $('.fbuy__container') // my target
		}
	});
});
	
$('a.expand-more-popular-categories').click(function(){
	$(this).closest('div').find('div.more-popular-categories').removeClass('hidden');
	$(this).closest('div').find('a.collapse-more-popular-categories').removeClass('hidden');
	$(this).addClass('hidden');
	return false;
});

$('a.collapse-more-popular-categories').click(function(){
	$(this).closest('div').find('div.more-popular-categories').addClass('hidden');
	$(this).closest('div').find('a.expand-more-popular-categories').removeClass('hidden');
	$(this).addClass('hidden');
	return false;
});

$('a.expand-more-categories').click(function(){
	$(this).closest('div').find('div.more-categories').removeClass('hidden');
	$(this).closest('div').find('a.collapse-more-categories').removeClass('hidden');
	$(this).addClass('hidden');
	return false;
});

$('a.collapse-more-categories').click(function(){
	$(this).closest('div').find('div.more-categories').addClass('hidden');
	$(this).closest('div').find('a.expand-more-categories').removeClass('hidden');
	$(this).addClass('hidden');
	return false;
});

/*
$(window).scroll(function() {
	if ($(this).scrollTop() > 1 && $('#emptycart').length == 0){  
		$('#topline').addClass("sticky");
	}
	else{
		$('#topline').removeClass("sticky");
	}
});
*/

function activateSubmenu(row) {
	var $row = $(row),
	  $submenu = $row.children('.tech-submenu');

	$row.addClass('hover');
	$submenu.css({
		display: 'block'
	});
}

function deactivateSubmenu(row) {
	var $row = $(row),
		$submenu = $row.children('.tech-submenu');

	$row.removeClass('hover');
	$submenu.css('display', 'none');
}

var initialPopup = true;

window.addEventListener('popstate', function (event) {
	if (event.state != undefined && event.state.mode != undefined) {
		if (event.state.mode == 'brand') {
			brand_request_ajax(event.state.params, false);
		}
		if (event.state.mode == 'products') {
			var href_str = "";
			var params_str = "";
			
			for(var index in event.state.params)
			{
				if (event.state.params[index].key == "url")
					href_str = event.state.params[index].value;
				else
				{
					if (params_str.length == 0)
						params_str = "?";
					else
						params_str += "&";
					params_str += event.state.params[index].key + "=" + event.state.params[index].value;
				}
			}
			
			if (params_str.length > 0)
				href_str = href_str + params_str;
			
			location.href = href_str;
			return false;
		}
		if (event.state.mode == 'product') {
			$('#main-left-container').hide();
			if ($('#main-right-container').hasClass('col-xs-9')) {
				$('#main-right-container').removeClass('col-xs-9');
				$('#main-right-container').addClass('col-xs-12');
			}

			var category_path = event.state.category_path;
			var selected_category_id = event.state.selected_category_id;

			/* Свернем другие категории */
			$('#categories a.active').removeClass('active');
			$('#categories li:not(.collapsed) ' + mc_nested_level).each(function () {
				var icon_minus = $(this).find('i.fa-minus');
				if (icon_minus.length > 0)
					icon_minus.trigger('click');
			});

			if (category_path.length > 0) {
				var path = category_path.split(',');
				while (path.length > 0) {
					var id = path[0];
					var li = $('#categories li[data-id=' + id + ']');
					var icon_plus = li.find('i.fa-plus:first');
					if (icon_plus.length > 0)
						icon_plus.trigger('click');
					path.shift();
				}
			}

			if (selected_category_id.length > 0) {
				var li = $('#categories li[data-id=' + selected_category_id + ']');
				var icon_plus = li.find('i.fa-plus');
				if (icon_plus.length > 0)
					icon_plus.trigger('click');
				$('#categories li[data-id=' + selected_category_id + '] a:first').addClass('active');
			}

			product_request_ajax(event.state.url, false);
		}
		if (event.state.mode == 'material') {
			$('#main-left-container').show();
			if ($('#main-right-container').hasClass('col-xs-12')) {
				$('#main-right-container').removeClass('col-xs-12');
				$('#main-right-container').addClass('col-xs-9');
			}

			/* Свернем другие категории */
			$('#categories a.active').removeClass('active');
			$('#categories li:not(.collapsed) ' + mc_nested_level).each(function () {
				var icon_minus = $(this).find('i.fa-minus');
				if (icon_minus.length > 0)
					icon_minus.trigger('click');
			});

			$('#collapseCategory').addClass('in');

			material_request_ajax(event.state.url, false);
		}
		if (event.state.mode == 'cart') {
			cart_request_ajax(false);
		}
		if (event.state.mode == 'compare') {
			compare_request_ajax(false);
		}
		if (event.state.mode == 'order'){
			location.href = event.state.url;
		}
		if (event.state.mode == 'account'){
			location.href = event.state.url;
		}
	}
});

if (typeof history.pushState != undefined) {
	if (mc_module_module == "ProductsController") {
		history.replaceState({ mode: 'products', params: [
			{ key: 'url', value: mc_config_root_url + mc_module_url + mc_current_url},
		].concat(mc_params_arr), selected_category_id: mc_selected_category_id, category_path: mc_category_path}, null, encodeURI(decodeURI(document.location.href)));
	} else if (mc_module_module == "ProductController") {
		history.replaceState({ mode: 'product', url: encodeURI(decodeURI(document.location.href)), category_path: mc_category_path, selected_category_id: mc_selected_category_id}, null, encodeURI(decodeURI(document.location.href)));
	} else if (mc_module_module == "PagesController" || mc_module_module == "MainController") {
		history.replaceState({ mode: 'material', url: encodeURI(decodeURI(document.location.href))}, null, encodeURI(decodeURI(document.location.href)));
	} else if (mc_module_module == "OrderController") {
		history.replaceState({ mode: 'order', url: encodeURI(decodeURI(document.location.href))}, null, encodeURI(decodeURI(document.location.href)));
	} else if (mc_module_module == "BrandController") {
		history.replaceState({ mode: 'brand', params: [
			{ key: 'url', value: mc_config_root_url + mc_module_url + mc_current_url},
		].concat(mc_params_arr)}, null, encodeURI(decodeURI(document.location.href)));
	} else if (mc_module_module == "AccountController") {
		history.replaceState({ mode: 'account', url: encodeURI(decodeURI(document.location.href))}, null, encodeURI(decodeURI(document.location.href)));
	}
}