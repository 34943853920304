$(function(){
	//$("#buy1click input[name=phone_number], #buy1click-credit input[name=phone_number], #contact-center input[name=phone_number], #resume-form input[name=phone_number]").mask("+7 (999) 999-99-99");
	
	$('textarea[name=comment]').autosize();
});

$('#delivery-price-modal-form').on('show.bs.modal', function (e) {
	$.ajax({
		url: mc_config_root_url + "/ajax/get_data.php",
		type: "POST",
		data: {
			object: 'materials',
			mode: 'get_material',
			material_id : 1359,
			from_market : $('#delivery-price-modal-form').attr('data-from-market'),
			session_id: mc_session_id
		},
		success: function(data){
			if (data.success)
			{
				$('#delivery-price-modal-form .modal-body').html(data.data);
			}
		}
	});
})

$('#make-order-one-click').click(function(){
	//if ($('#buy1click input[name=phone_number]').mask().length == 0)
	if ($('#buy1click input[name=phone_number]').val().length < 10)
	{
		$('#buy1click input[name=phone_number]').focus();
		$('#buy1click-phone-required').show();
		return false;
	}

	if (typeof grecaptcha !== 'undefined' && grecaptcha.getResponse(widgetCaptchaCartOneClick).length == 0) {
		$('#recaptcha-block-error-cart-one-click').show();
		return false;
	}

	$('#make-order-one-click').addClass('disabled');
	$('#save_order_1click_spinner').removeClass('hidden');

	if(window.Comagic && window.Comagic.addOfflineRequest) {
		var cData = {
			phone: $('#buy1click input[name=phone_number]').val(),
			message: $('#buy1click textarea[name="comment"]').val(),
		};
		window.Comagic.addOfflineRequest(cData, function () {
			$('#order-one-click-form').submit();
		});
		//setTimeout(function () {$('#order-one-click-form').submit();;}, 10000);
	}
	else {
		$('#order-one-click-form').submit();
	}

	
	return false;
});

function sendData1ClickToComagic() {
	console.log('Try send comagic 1click request');
	try {
		Comagic.addOfflineRequest({
		  name: '',
		  email: '',
		  phone: $('input[name=phone_number]').val(),
		  message: 'Заказ корзина 1 клик',
		});
		console.log('send request 1click complete');
	} catch (err) {
		console.log('  Catch error(' + err.message + ')');
	}
}

$('#order-one-click-form').submit(function(){
	//if ($('#buy1click input[name=phone_number]').mask().length == 0)
	//if ($('#buy1click input[name=phone_number]').length == 0)
	if ($(this).find('input[name=phone_number]').val().length < 10)
	{
		$('#make-order-one-click').removeClass('disabled');
		$('#save_order_1click_spinner').addClass('hidden');
		$('#buy1click input[name=phone_number]').focus();
		$('#buy1click-phone-required').show();
		return false;
	}

	if (typeof grecaptcha !== 'undefined' && grecaptcha.getResponse(widgetCaptchaCartOneClick).length == 0) {
		$('#recaptcha-block-error-cart-one-click').show();
		return false;
	}
	
	//sendData1ClickToComagic();
});

$('#buy1click input[name=phone_number]').keypress(function(){
	$('#buy1click-phone-required').hide();
});
/*****/
$('#make-order-credit-one-click').click(function(){
	//if ($('#buy1click-credit input[name=phone_number]').mask().length == 0)
	if ($('#buy1click-credit input[name=phone_number]').val().length < 10)
	{
		$('#buy1click-credit input[name=phone_number]').focus();
		$('#buy1click-credit-phone-required').show();
		return false;
	}
	$('#make-order-credit-one-click').addClass('disabled');
	$('#save_order_credit_1click_spinner').removeClass('hidden');
	if (window.Comagic && window.Comagic.addOfflineRequest) {
		var cData = {
			phone: $('#order-credit-one-click-form input[name=phone_number]').val(),
			message: $('#order-credit-one-click-form textarea[name="comment"]').val(),
		};
		window.Comagic.addOfflineRequest(cData, function () {
			$('#order-credit-one-click-form').submit();
		});
		//setTimeout(function () {$('#order-credit-one-click-form').submit();}, 3000);
	}
	else {
		$('#order-credit-one-click-form').submit();
	}
	return false;
});
$('#order-credit-one-click-form').submit(function(){
	//if ($('#buy1click-credit input[name=phone_number]').mask().length == 0)
	/*if ($('#buy1click-credit input[name=phone_number]').length == 0)
	{
		$('#buy1click-credit input[name=phone_number]').focus();
		$('#buy1click-credit-phone-required').show();
		return false;
	}*/
	if ($(this).find('input[name=phone_number]').val().length < 10)
	{
		$('#make-order-credit-one-click').removeClass('disabled');
		$('#save_order_credit_1click_spinner').addClass('hidden');
		$('#buy1click-credit input[name=phone_number]').focus();
		$('#buy1click-phone-required').show();
		return false;
	}
	
	if (typeof grecaptcha !== 'undefined' && grecaptcha.getResponse(widgetCaptchaCartCredit).length == 0) {
		$('#recaptcha-block-error-cart-credit').show();
		return false;
	}
});
$('#buy1click-credit input[name=phone_number]').keypress(function(){
	$('#buy1click-credit-phone-required').hide();
});
/***************/
$('#send-callback').click(function(){
	$('#recaptcha-block-error-callback').hide();
	$('#contact-center-phone-required').hide();
	//if ($('#contact-center input[name=phone_number]').mask().length == 0)
	if ($('#contact-center input[name=phone_number]').val().length < 10)
	{
		$('#contact-center input[name=phone_number]').focus();
		$('#contact-center-phone-required').show();
		return false;
	}
	
	if (typeof grecaptcha !== 'undefined' && grecaptcha.getResponse(widgetCaptchaCallback).length == 0) {
		$('#recaptcha-block-error-callback').show();
		return false;
	}
	
	$('#callback-form').submit();
	return false;
});

$('#callback-form').submit(function(){
	$('#recaptcha-block-error-callback').hide();
	$('#contact-center-phone-required').hide();
	//if ($('#contact-center input[name=phone_number]').mask().length == 0)
	if ($('#contact-center input[name=phone_number]').val().length < 10)
	{
		$('#contact-center input[name=phone_number]').focus();
		$('#contact-center-phone-required').show();
		return false;
	}
	
	if (typeof grecaptcha !== 'undefined' && grecaptcha.getResponse(widgetCaptchaCallback).length == 0) {
		$('#recaptcha-block-error-callback').show();
		return false;
	}
	
	var phone_field = encodeURIComponent($('#contact-center input[name=phone_number]').val());
	var user_name = encodeURIComponent($('#contact-center input[name=user_name]').val());
	var call_time_field = encodeURIComponent($('#contact-center input[name=call_time]').val());
	var message_field = encodeURIComponent($('#contact-center textarea[name=message]').val());
	
	var callback_recaptcha = '';
	if (typeof grecaptcha !== 'undefined')
		callback_recaptcha = '&g-recaptcha-response=' + grecaptcha.getResponse(widgetCaptchaCallback);
	
	var url = mc_callback_module_url + "&phone_number=" + phone_field + "&call_time=" + call_time_field + "&message=" + message_field + "&user_name=" + user_name + callback_recaptcha;
	$.ajax({
		type: 'GET',
		url: url,
		success: function(data) {
			$('#callback-part').hide();
			$('#send-callback').hide();
			$('#callback-close').show();
			if (data.success)
				$('#callback-success-part').show();
			else
				$('#callback-error-part').show();
			if (typeof Comagic !== 'undefined') {
				Comagic.addOfflineRequest({
				  name: $('#contact-center input[name=user_name]').val(),
				  phone: $('#contact-center input[name=phone_number]').val(),
				  message: 'Заказ звонка #' + data.id
				});
			}
		}
	});
	return false;
});

function show_resume_form(){
	$('#resume-part').show();
	$('#send-resume').show();
	$('#resume-close').hide();
	$('#resume-success-part').hide();
	$('#resume-error-part').hide();
	$('#send-resume').addClass('disabled');
	$('#resume-form input[name=resume_name]').val('');
	$('#resume-form input[name=phone_number]').val('');
	$('#resume-form input[name=resume_post]').val('');
	$('#resume-form input[name=resume_email]').val('');
	$('#resume-form textarea[name=resume_text]').val('');
	$('#resume-form input[name=resume_name]').focus();
	$('#resume_accept_personal').prop('checked', false);
	$('#resume-form-modal').modal('show');
}

$('#resume_accept_personal').click(function(){
	if (this.checked)
		$('#send-resume').removeClass('disabled');
	else
		$('#send-resume').addClass('disabled');
});

$('#send-resume').click(function(){
	$('#resume-form').submit();
	return false;
});

$('#resume-form').submit(function(){
	
	$('#resume-form-name-required').hide();
	$('#resume-form-phone-required').hide();
	$('#resume-form-post-required').hide();
	$('#resume-form-file-required').hide();
	$('#resume-form-text-required').hide();
	
	if ($('#resume-form input[name=resume_name]').val().length == 0)
	{
		$('#resume-form input[name=resume_name]').focus();
		$('#resume-form-name-required').show();
		return false;
	}
	//if ($('#resume-form input[name=phone_number]').mask().length == 0)
	if ($('#resume-form input[name=phone_number]').val().length < 10)
	{
		$('#resume-form input[name=phone_number]').focus();
		$('#resume-form-phone-required').show();
		return false;
	}
	if ($('#resume-form input[name=resume_post]').val().length == 0)
	{
		$('#resume-form input[name=resume_post]').focus();
		$('#resume-form-post-required').show();
		return false;
	}
	
	if($('#resume-form input[name=resume_file]').get(0).files.length == 0 ){
		$('#resume-form input[name=resume_file]').focus();
		$('#resume-form-file-required').show();
		return false;
	}
	
	if ($('#resume-form textarea[name=resume_text]').val().length == 0)
	{
		$('#resume-form textarea[name=resume_text]').focus();
		$('#resume-form-text-required').show();
		return false;
	}
	
	$('#send-resume').addClass('disabled');
	
	var formData = new FormData($('#resume-form')[0]);
	
	$.ajax({
		url: mc_resume_module_url,
		type: 'POST',
		data: formData,
		contentType: false,
		processData: false,
		success: function(data){
			$('#resume-part').hide();
			$('#send-resume').hide();
			$('#resume-close').show();
			if (data.success)
				$('#resume-success-part').show();
			else
				$('#resume-error-part').show();
			
			$('#send-resume').removeClass('disabled');
		},
		error: function(data) {
			$('#send-resume').removeClass('disabled');
		}
	});
	
	return false;
});

$('#complain-form .modal-body input[type=radio]').change(function(){
	var v = $(this).val();
	if (v == 'other')
		$('#complain-form .modal-body textarea').prop('disabled', false);
	else
		$('#complain-form .modal-body textarea').prop('disabled', true);
});

$('#make-claim').click(function(){
	if ($('#complain-form .modal-body input[type=radio]:checked').val() != 'other')
		$('#complain-form .modal-body textarea').val('');
	$.ajax({
		url: mc_product_module_url,
		type: 'POST',
		data: {
			review_id: $('#complain-form input[name=claim_review_id]').val(),
			claim_type: $('#complain-form .modal-body input[type=radio]:checked').val(),
			claim_text: $('#complain-form .modal-body textarea').val()
		},
		dataType: 'json',
		success: function(data){
			$('#claim-part').hide();
			$('#make-claim').hide();
			$('#claim-close').show();
			if (data.success)
				$('#claim-success-part').show();
			else
				$('#claim-error-part').show();
		}
	});
	return false;
});

$('#after-buy-form-make-order').click(function(){
	document.location.href = mc_config_root_url + '/cart/';
	return false;
});