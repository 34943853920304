$('li.subscription-item a.delete-from-subscription').click(function(){
	var id = $(this).attr('data-variant-id');
	var a = $(this);
	$.ajax({
		url: mc_config_root_url + "/ajax/get_data.php",
		type: "POST",
		data: {
			object: 'products',
			mode: 'variant-togglesubscription',
			vid : id,
			uid : mc_user_id,
			session_id: mc_session_id
		},
		success: function(data){
			if (data.success)
				location.reload();
		}
	});
	return false;
});

$('ul.subscription-list a.subscription-buy').click(function(){
	var variant_id = $(this).closest('li').find('select[name=variant_id] option:selected').val();
	var href = mc_cart_module_url + "?action=add_variant&variant_id="+variant_id+"&amount=1";
	$.get(href, function(data){
		if (data.success) {
			$('#cart').replaceWith(data.header);
			bbar_update(data.fbbar);
			addVKReachGoalEvent(variant_id);
			if (data.additional_result != undefined && data.additional_result.message == "no_stock")
			{
				$('#no_stock').html('Вы можете купить товар в количестве не более ' + data.additional_result.max_stock + ' штук');
				$('#no_stock').show();
			}
			else
				if (data.additional_result != undefined && data.additional_result.added_form !=undefined)
				{
					$('#after-buy-form .modal-header span.modal-title').html(data.additional_result.modal_header);
					$('#after-buy-form .modal-body').html(data.additional_result.added_form);
					$('#after-buy-form').modal();
				}
		}
	});
	return false;
});