$(document).ready(function(){
	if ($('.products-slideshow').length > 0) {
		var swiper_products = new Swiper('.products-slideshow', {
			slidesPerView: 1,
			spaceBetween: 0,
			speed: 1000,
			autoplay: {
				delay: 4200,
			},
			autoplayDisableOnInteraction: false,
			loop: false,
			preventClicks: false,
			preventClicksPropagation: false,
            breakpoints: {
				0: {
					slidesPerView: 1
				},
                540: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 3
                },
                999: {
                    slidesPerView: 4
                }
            },
			// Navigation arrows
			  navigation: {
				nextEl: '.swiper-next',
				prevEl: '.swiper-prev',
			  },
		});
	}
	
	if ($('.related-slideshow').length > 0) {
		var swiper_related = new Swiper('.related-slideshow', {
			slidesPerView: 1,
			spaceBetween: 0,
			speed: 1000,
			autoplay: {
				delay: 4200,
			},
			autoplayDisableOnInteraction: false,
			loop: false,
			preventClicks: false,
			preventClicksPropagation: false,
            breakpoints: {
				0: {
					slidesPerView: 1
				},
                540: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 3
                },
                999: {
                    slidesPerView: 4
                }
            },
			// Navigation arrows
			  navigation: {
				nextEl: '.swiper-next',
				prevEl: '.swiper-prev',
			  },
		});
	}
	
	if ($('.viewedproducts-slideshow').length > 0) {
		var swiper_viewedproducts = new Swiper('.viewedproducts-slideshow', {
			slidesPerView: 1,
			spaceBetween: 0,
			speed: 1000,
			autoplay: {
				delay: 4200,
			},
			autoplayDisableOnInteraction: false,
			loop: false,
			preventClicks: false,
			preventClicksPropagation: false,
            breakpoints: {
				0: {
					slidesPerView: 1
				},
                540: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 3
                },
                999: {
                    slidesPerView: 4
                }
            },
			// Navigation arrows
			  navigation: {
				nextEl: '.swiper-next',
				prevEl: '.swiper-prev',
			  },
		});
	}
});