$(document).ready(function(){
	$('ul.plitka').on('change', 'li.plitka-item select[name=variant_id]', function(){
		var li = $(this).closest('li');
		var option = $(this).find('option:selected');
		li.find('div.plitka-status span').each(function(){
			$(this).hide();
		});
		var stock = parseInt(option.attr('data-stock'));
		if (stock > 0)
		{
			li.find('span.in-stock').show();
			li.find('a.plitka-buy').show();
		}
		if (stock == 0)
		{
			li.find('span.out-of-stock').show();
			li.find('a.plitka-buy').hide();
		}
		if (stock < 0)
		{
			li.find('span.to-order').show();
			li.find('a.plitka-buy').show();
		}
		var price = parseFloat(option.attr('data-price'));
		var price_old = parseFloat(option.attr('data-price-old'));
		if (price > 0)
			li.find('div.plitka-price').show().html(option.attr('data-price-convert')+' <span>' + mc_main_currency_sign + '</span>');
		else
			li.find('div.plitka-price').hide();
		if (price_old > 0)
			li.find('div.plitka-old-price').show().html(option.attr('data-price-old-convert')+' <span>' + mc_main_currency_sign + '</span>');
		else
			li.find('div.plitka-old-price').hide();
	});
});

$('li.favorite-item a.delete-from-favorite').click(function(){
	var id = $(this).data('id');
	var a = $(this);
	$.ajax({
		url: mc_config_root_url + "/ajax/get_data.php",
		type: "POST",
		data: {
			object: 'products',
			mode: 'product-togglefavorite',
			pid : id,
			uid : mc_user_id,
			session_id: mc_session_id
		},
		success: function(data){
			if (data.success)
				location.reload();
		}
	});
	return false;
});


/*
$('#main-right-container').on('click', '.delete-from-favorite', function(){
	var element = $(this);
	var product_id = element.attr('data-id');
	var user_id = mc_user_id;
	var data = {
		object: 'products',
		mode: 'product-deletefavorite',
		pid : product_id,
		uid : user_id,
		session_id: mc_session_id
	};
	$.get(href, function(data){
		if (data.success)
		{
			$('#favorites-informer').html(' <i class="fa fa-heart"></i> ' + response.count);
			element.closest('li.favorite-item').fadeOut();
		}
	});
	return false;
});
*/

$('ul.favotite-list a.favorite-buy').click(function(){
	var variant_id = $(this).closest('li').find('select[name=variant_id] option:selected').val();
	var href = mc_config_root_url + "/cart/?action=add_variant&variant_id="+variant_id+"&amount=1";
	$.get(href, function(data){
		if (data.success)
			$('#cart-placeholder').html(data.data);
	});
	var obj1 = $(this).offset();
	var obj2 = $('#cart-placeholder').offset();
	var dx = obj1.left - obj2.left;
	var dy = obj1.top - obj2.top;
	var distance = Math.sqrt(dx * dx + dy * dy);
	$(this).closest('.favorite-item').find('a.favorite-image img').effect("transfer", { to: $("#cart-placeholder"), className: "transfer_class" }, distance);	
	$('.transfer_class').html($(this).closest('.favorite-item').find('a.favorite-image').html());
	$('.transfer_class').find('img').css('height', '100%');
	return false;
});