$(document).ready(function(){
	$(".fancybox").fancybox({
		autoResize: true,
		autoCenter: true,
		prevEffect	: 'none',
		nextEffect	: 'none',
		helpers	: {
			title	: {
				type: 'outside'
			},
			thumbs	: {
				width	: 50,
				height	: 50
			},
			overlay : {
			   locked     : false
			}
		}
	});
	
	if (typeof mc_reviews_material_module_url != 'undefined') {
		$.get(mc_reviews_material_module_url, function(data){
			$('#reviews-material-list').html(data);
			
			if ($('body script[src$="elements/reviews_material.js"]').length == 0) {
				var s = document.createElement("script");
				s.type = "text/javascript";
				s.src = mc_path_frontend_template + "/js/elements/reviews_material.js";
				$("body").append(s);
			}
		});
	}
});

if (typeof mc_settings_reviews_enabled != 'undefined' && mc_settings_reviews_enabled) {
	$('#reviews-material-list').on('focusout', '.my-review input[name=name]', function(){
		var index = $(this).closest('.my-review').data('index');
		if ($(this).val().length == 0)
			$('#name-block-error-'+index).show();
		else
			$('#name-block-error-'+index).hide();
	});
	
	$('#reviews-material-list').on('focusout', '.my-review textarea[name=comments]', function(){
		var index = $(this).closest('.my-review').data('index');
		if ($(this).val().length == 0)
			$('#comment-block-error-'+index).show();
		else
			$('#comment-block-error-'+index).hide();
	});

	$('#reviews-material-list').on('click', '#add-review-1, #add-review-2', function(){
	
		var isError = false;
		var index = $(this).data('index');
		var review = $(this).closest('.my-review');
		$('#recaptcha-block-error-'+index).hide();
		
		if (review.find('input[name=name]').val().length == 0){
			$('#name-block-error-'+index).show();
			isError = true;
		}
		
		if (review.find('textarea[name=comments]').val().length == 0){
			$('#comment-block-error-'+index).show();
			isError = true;
		}
		
		if (typeof grecaptcha !== 'undefined' && grecaptcha.getResponse().length == 0) {
			$('#recaptcha-block-error-'+index).show();
			isError = true;
		}
		
		if (isError)
			return false;
		
		$('#name-block-error-'+index).hide();
		$('#comment-block-error-'+index).hide();
	
		$.ajax({
			url: mc_pages_module_url,
			type: 'POST',
			data: {
				name: review.find('input[name=name]').val(),
				comments: review.find('textarea[name=comments]').val(),
				material_id: mc_material_id,
				temp_id: review.find('input[name=temp_id]').val(),
				'g-recaptcha-response': (typeof grecaptcha !== 'undefined') ? grecaptcha.getResponse(widgetCaptchaMaterialNewReview) : ''
			},
			dataType: 'json',
			success: function(data){
				$.get(mc_material_reviews_module_url, function(data){
					$('#reviews-material-list').html(data);
					$('#add-review-result-'+index).show();
				});
			}
		});
		return false;
	});
	
	$('#reviews-material-list').on('click', '.usergrade-yes', function(){
		var r = $(this).closest('div.review-body');
		$.ajax({
			url: mc_pages_module_url,
			type: 'POST',
			data: {
				review_id: r.data('id'),
				helpful: 1,
				nothelpful: 0
			},
			dataType: 'json',
			success: function(data){
				if (data.success){
					r.find('.usergrade-yes').next('span:first').html(data.helpful);
					r.find('.usergrade-no').next('span:first').html(data.nothelpful);
				}
				else
					if (data.message == 'exists')
						alert('Вы уже отметили полезность этого отзыва!');
			}
		});
		return false;
	});
	
	$('#reviews-material-list').on('click', '.usergrade-no', function(){
		var r = $(this).closest('div.review-body');
		$.ajax({
			url: mc_pages_module_url,
			type: 'POST',
			data: {
				review_id: r.data('id'),
				helpful: 0,
				nothelpful: 1
			},
			dataType: 'json',
			success: function(data){
				if (data.success){
					r.find('.usergrade-yes').next('span:first').html(data.helpful);
					r.find('.usergrade-no').next('span:first').html(data.nothelpful);
				}
				else
					if (data.message == 'exists')
						alert('Вы уже отметили полезность этого отзыва!');
			}
		});
		return false;
	});
	
	$('#reviews-material-list').on('click', '.rate-sort-item a', function(){
		var url = $(this).attr('href');
		var sort_method = $(this).closest('div').data('sort');
		var mainUrl = mc_material_reviews_sort_module_url + sort_method;
		
		if (typeof history.pushState != undefined) {
			history.pushState({ mode: 'material', url: mainUrl},null,encodeURI(decodeURI(mainUrl)));
		}
		
		$.get(url, function(data){
			$('#reviews-material-list').html(data);
		});
		return false;
	});
}