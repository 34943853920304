(function(h) {
    var g = {
            vertical: false,
            rtl: false,
            start: 1,
            offset: 1,
            size: null,
            scroll: 3,
            visible: null,
            animation: "normal",
            easing: "swing",
            auto: 0,
            wrap: null,
            initCallback: null,
            setupCallback: null,
            reloadCallback: null,
            itemLoadCallback: null,
            itemFirstInCallback: null,
            itemFirstOutCallback: null,
            itemLastInCallback: null,
            itemLastOutCallback: null,
            itemVisibleInCallback: null,
            itemVisibleOutCallback: null,
            animationStepCallback: null,
            buttonNextHTML: "<div></div>",
            buttonPrevHTML: "<div></div>",
            buttonNextEvent: "click",
            buttonPrevEvent: "click",
            buttonNextCallback: null,
            buttonPrevCallback: null,
            itemFallbackDimension: null
        },
        e = false;
    h(window).bind("load.jcarousel", function() {
        e = true
    });
    h.jcarousel = function(j, t) {
        this.options = h.extend({}, g, t || {});
        this.locked = false;
        this.autoStopped = false;
        this.container = null;
        this.clip = null;
        this.list = null;
        this.buttonNext = null;
        this.buttonPrev = null;
        this.buttonNextState = null;
        this.buttonPrevState = null;
        if (!t || t.rtl === undefined) {
            this.options.rtl = (h(j).attr("dir") || h("html").attr("dir") || "").toLowerCase() == "rtl"
        }
        this.wh = !this.options.vertical ? "width" : "height";
        this.lt = !this.options.vertical ? (this.options.rtl ? "right" : "left") : "top";
        var b = "",
            d = j.className.split(" ");
        for (var o = 0; o < d.length; o++) {
            if (d[o].indexOf("jcarousel-skin") != -1) {
                h(j).removeClass(d[o]);
                b = d[o];
                break
            }
        }
        if (j.nodeName.toUpperCase() == "UL" || j.nodeName.toUpperCase() == "OL") {
            this.list = h(j);
            this.clip = this.list.parents(".jcarousel-clip");
            this.container = this.list.parents(".jcarousel-container")
        } else {
            this.container = h(j);
            this.list = this.container.find("ul,ol").eq(0);
            this.clip = this.container.find(".jcarousel-clip")
        }
        if (this.clip.size() === 0) {
            this.clip = this.list.wrap("<div></div>").parent()
        }
        if (this.container.size() === 0) {
            this.container = this.clip.wrap("<div></div>").parent()
        }
        if (b !== "" && this.container.parent()[0].className.indexOf("jcarousel-skin") == -1) {
            this.container.wrap('<div class=" ' + b + '"></div>')
        }
        this.buttonPrev = h(".jcarousel-prev", this.container);
        if (this.buttonPrev.size() === 0 && this.options.buttonPrevHTML !== null) {
            this.buttonPrev = h(this.options.buttonPrevHTML).appendTo(this.container)
        }
        this.buttonPrev.addClass(this.className("jcarousel-prev"));
        this.buttonNext = h(".jcarousel-next", this.container);
        if (this.buttonNext.size() === 0 && this.options.buttonNextHTML !== null) {
            this.buttonNext = h(this.options.buttonNextHTML).appendTo(this.container)
        }
        this.buttonNext.addClass(this.className("jcarousel-next"));
        this.clip.addClass(this.className("jcarousel-clip")).css({
            position: "relative"
        });
        this.list.addClass(this.className("jcarousel-list")).css({
            overflow: "hidden",
            position: "relative",
            top: 0,
            margin: 0,
            padding: 0
        }).css((this.options.rtl ? "right" : "left"), 0);
        this.container.addClass(this.className("jcarousel-container")).css({
            position: "relative"
        });
        if (!this.options.vertical && this.options.rtl) {
            this.container.addClass("jcarousel-direction-rtl").attr("dir", "rtl")
        }
        var i = this.options.visible !== null ? Math.ceil(this.clipping() / this.options.visible) : null;
        var c = this.list.children("li");
        var a = this;
        if (c.size() > 0) {
            var u = 0,
                s = this.options.offset;
            c.each(function() {
                a.format(this, s++);
                u += a.dimension(this, i)
            });
            this.list.css(this.wh, (u + 100) + "px");
            if (!t || t.size === undefined) {
                this.options.size = c.size()
            }
        }
        this.container.css("display", "block");
        this.buttonNext.css("display", "block");
        this.buttonPrev.css("display", "block");
        this.funcNext = function() {
            a.next();
            return false
        };
        this.funcPrev = function() {
            a.prev();
            return false
        };
        this.funcResize = function() {
            if (a.resizeTimer) {
                clearTimeout(a.resizeTimer)
            }
            a.resizeTimer = setTimeout(function() {
                a.reload()
            }, 100)
        };
        if (this.options.initCallback !== null) {
            this.options.initCallback(this, "init")
        }
        if (!e && f.isSafari()) {
            this.buttons(false, false);
            h(window).bind("load.jcarousel", function() {
                a.setup()
            })
        } else {
            this.setup()
        }
    };
    var f = h.jcarousel;
    f.fn = f.prototype = {
        jcarousel: "0.2.9"
    };
    f.fn.extend = f.extend = h.extend;
    f.fn.extend({
        setup: function() {
            this.first = null;
            this.last = null;
            this.prevFirst = null;
            this.prevLast = null;
            this.animating = false;
            this.timer = null;
            this.resizeTimer = null;
            this.tail = null;
            this.inTail = false;
            if (this.locked) {
                return
            }
            this.list.css(this.lt, this.pos(this.options.offset) + "px");
            var a = this.pos(this.options.start, true);
            this.prevFirst = this.prevLast = null;
            this.animate(a, false);
            h(window).unbind("resize.jcarousel", this.funcResize).bind("resize.jcarousel", this.funcResize);
            if (this.options.setupCallback !== null) {
                this.options.setupCallback(this)
            }
        },
        reset: function() {
            this.list.empty();
            this.list.css(this.lt, "0px");
            this.list.css(this.wh, "10px");
            if (this.options.initCallback !== null) {
                this.options.initCallback(this, "reset")
            }
            this.setup()
        },
        reload: function() {
            if (this.tail !== null && this.inTail) {
                this.list.css(this.lt, f.intval(this.list.css(this.lt)) + this.tail)
            }
            this.tail = null;
            this.inTail = false;
            if (this.options.reloadCallback !== null) {
                this.options.reloadCallback(this)
            }
            if (this.options.visible !== null) {
                var b = this;
                var a = Math.ceil(this.clipping() / this.options.visible),
                    c = 0,
                    d = 0;
                this.list.children("li").each(function(i) {
                    c += b.dimension(this, a);
                    if (i + 1 < b.first) {
                        d = c
                    }
                });
                this.list.css(this.wh, c + "px");
                this.list.css(this.lt, -d + "px")
            }
            this.scroll(this.first, false)
        },
        lock: function() {
            this.locked = true;
            this.buttons()
        },
        unlock: function() {
            this.locked = false;
            this.buttons()
        },
        size: function(a) {
            if (a !== undefined) {
                this.options.size = a;
                if (!this.locked) {
                    this.buttons()
                }
            }
            return this.options.size
        },
        has: function(c, b) {
            if (b === undefined || !b) {
                b = c
            }
            if (this.options.size !== null && b > this.options.size) {
                b = this.options.size
            }
            for (var d = c; d <= b; d++) {
                var a = this.get(d);
                if (!a.length || a.hasClass("jcarousel-item-placeholder")) {
                    return false
                }
            }
            return true
        },
        get: function(a) {
            return h(">.jcarousel-item-" + a, this.list)
        },
        add: function(i, a) {
            var d = this.get(i),
                n = 0,
                r = h(a);
            if (d.length === 0) {
                var b, j = f.intval(i);
                d = this.create(i);
                while (true) {
                    b = this.get(--j);
                    if (j <= 0 || b.length) {
                        if (j <= 0) {
                            this.list.prepend(d)
                        } else {
                            b.after(d)
                        }
                        break
                    }
                }
            } else {
                n = this.dimension(d)
            }
            if (r.get(0).nodeName.toUpperCase() == "LI") {
                d.replaceWith(r);
                d = r
            } else {
                d.empty().append(a)
            }
            this.format(d.removeClass(this.className("jcarousel-item-placeholder")), i);
            var c = this.options.visible !== null ? Math.ceil(this.clipping() / this.options.visible) : null;
            var s = this.dimension(d, c) - n;
            if (i > 0 && i < this.first) {
                this.list.css(this.lt, f.intval(this.list.css(this.lt)) - s + "px")
            }
            this.list.css(this.wh, f.intval(this.list.css(this.wh)) + s + "px");
            return d
        },
        remove: function(c) {
            var b = this.get(c);
            if (!b.length || (c >= this.first && c <= this.last)) {
                return
            }
            var a = this.dimension(b);
            if (c < this.first) {
                this.list.css(this.lt, f.intval(this.list.css(this.lt)) + a + "px")
            }
            b.remove();
            this.list.css(this.wh, f.intval(this.list.css(this.wh)) - a + "px")
        },
        next: function() {
            if (this.tail !== null && !this.inTail) {
                this.scrollTail(false)
            } else {
                this.scroll(((this.options.wrap == "both" || this.options.wrap == "last") && this.options.size !== null && this.last == this.options.size) ? 1 : this.first + this.options.scroll)
            }
        },
        prev: function() {
            if (this.tail !== null && this.inTail) {
                this.scrollTail(true)
            } else {
                this.scroll(((this.options.wrap == "both" || this.options.wrap == "first") && this.options.size !== null && this.first == 1) ? this.options.size : this.first - this.options.scroll)
            }
        },
        scrollTail: function(b) {
            if (this.locked || this.animating || !this.tail) {
                return
            }
            this.pauseAuto();
            var a = f.intval(this.list.css(this.lt));
            a = !b ? a - this.tail : a + this.tail;
            this.inTail = !b;
            this.prevFirst = this.first;
            this.prevLast = this.last;
            this.animate(a)
        },
        scroll: function(a, b) {
            if (this.locked || this.animating) {
                return
            }
            this.pauseAuto();
            this.animate(this.pos(a), b)
        },
        pos: function(I, P) {
            var O = f.intval(this.list.css(this.lt));
            if (this.locked || this.animating) {
                return O
            }
            if (this.options.wrap != "circular") {
                I = I < 1 ? 1 : (this.options.size && I > this.options.size ? this.options.size : I)
            }
            var a = this.first > I;
            var m = this.options.wrap != "circular" && this.first <= 1 ? 1 : this.first;
            var c = a ? this.get(m) : this.get(this.last);
            var L = a ? m : m - 1;
            var j = null,
                M = 0,
                d = false,
                i = 0,
                x;
            while (a ? --L >= I : ++L < I) {
                j = this.get(L);
                d = !j.length;
                if (j.length === 0) {
                    j = this.create(L).addClass(this.className("jcarousel-item-placeholder"));
                    c[a ? "before" : "after"](j);
                    if (this.first !== null && this.options.wrap == "circular" && this.options.size !== null && (L <= 0 || L > this.options.size)) {
                        x = this.get(this.index(L));
                        if (x.length) {
                            j = this.add(L, x.clone(true))
                        }
                    }
                }
                c = j;
                i = this.dimension(j);
                if (d) {
                    M += i
                }
                if (this.first !== null && (this.options.wrap == "circular" || (L >= 1 && (this.options.size === null || L <= this.options.size)))) {
                    O = a ? O + i : O - i
                }
            }
            var v = this.clipping(),
                l = [],
                Q = 0,
                p = 0;
            c = this.get(I - 1);
            L = I;
            while (++Q) {
                j = this.get(L);
                d = !j.length;
                if (j.length === 0) {
                    j = this.create(L).addClass(this.className("jcarousel-item-placeholder"));
                    if (c.length === 0) {
                        this.list.prepend(j)
                    } else {
                        c[a ? "before" : "after"](j)
                    }
                    if (this.first !== null && this.options.wrap == "circular" && this.options.size !== null && (L <= 0 || L > this.options.size)) {
                        x = this.get(this.index(L));
                        if (x.length) {
                            j = this.add(L, x.clone(true))
                        }
                    }
                }
                c = j;
                i = this.dimension(j);
                if (i === 0) {
                    throw new Error("jCarousel: No width/height set for items. This will cause an infinite loop. Aborting...")
                }
                if (this.options.wrap != "circular" && this.options.size !== null && L > this.options.size) {
                    l.push(j)
                } else {
                    if (d) {
                        M += i
                    }
                }
                p += i;
                if (p >= v) {
                    break
                }
                L++
            }
            for (var J = 0; J < l.length; J++) {
                l[J].remove()
            }
            if (M > 0) {
                this.list.css(this.wh, this.dimension(this.list) + M + "px");
                if (a) {
                    O -= M;
                    this.list.css(this.lt, f.intval(this.list.css(this.lt)) - M + "px")
                }
            }
            var K = I + Q - 1;
            if (this.options.wrap != "circular" && this.options.size && K > this.options.size) {
                K = this.options.size
            }
            if (L > K) {
                Q = 0;
                L = K;
                p = 0;
                while (++Q) {
                    j = this.get(L--);
                    if (!j.length) {
                        break
                    }
                    p += this.dimension(j);
                    if (p >= v) {
                        break
                    }
                }
            }
            var N = K - Q + 1;
            if (this.options.wrap != "circular" && N < 1) {
                N = 1
            }
            if (this.inTail && a) {
                O += this.tail;
                this.inTail = false
            }
            this.tail = null;
            if (this.options.wrap != "circular" && K == this.options.size && (K - Q + 1) >= 1) {
                var b = f.intval(this.get(K).css(!this.options.vertical ? "marginRight" : "marginBottom"));
                if ((p - b) > v) {
                    this.tail = p - v - b
                }
            }
            if (P && I === this.options.size && this.tail) {
                O -= this.tail;
                this.inTail = true
            }
            while (I-- > N) {
                O += this.dimension(this.get(I))
            }
            this.prevFirst = this.first;
            this.prevLast = this.last;
            this.first = N;
            this.last = K;
            return O
        },
        animate: function(b, l) {
            if (this.locked || this.animating) {
                return
            }
            this.animating = true;
            var k = this;
            var d = function() {
                k.animating = false;
                if (b === 0) {
                    k.list.css(k.lt, 0)
                }
                if (!k.autoStopped && (k.options.wrap == "circular" || k.options.wrap == "both" || k.options.wrap == "last" || k.options.size === null || k.last < k.options.size || (k.last == k.options.size && k.tail !== null && !k.inTail))) {
                    k.startAuto()
                }
                k.buttons();
                k.notify("onAfterAnimation");
                if (k.options.wrap == "circular" && k.options.size !== null) {
                    for (var i = k.prevFirst; i <= k.prevLast; i++) {
                        if (i !== null && !(i >= k.first && i <= k.last) && (i < 1 || i > k.options.size)) {
                            k.remove(i)
                        }
                    }
                }
            };
            this.notify("onBeforeAnimation");
            if (!this.options.animation || l === false) {
                this.list.css(this.lt, b + "px");
                d()
            } else {
                var a = !this.options.vertical ? (this.options.rtl ? {
                    right: b
                } : {
                    left: b
                }) : {
                    top: b
                };
                var c = {
                    duration: this.options.animation,
                    easing: this.options.easing,
                    complete: d
                };
                if (h.isFunction(this.options.animationStepCallback)) {
                    c.step = this.options.animationStepCallback
                }
                this.list.animate(a, c)
            }
        },
        startAuto: function(a) {
            if (a !== undefined) {
                this.options.auto = a
            }
            if (this.options.auto === 0) {
                return this.stopAuto()
            }
            if (this.timer !== null) {
                return
            }
            this.autoStopped = false;
            var b = this;
            this.timer = window.setTimeout(function() {
                b.next()
            }, this.options.auto * 1000)
        },
        stopAuto: function() {
            this.pauseAuto();
            this.autoStopped = true
        },
        pauseAuto: function() {
            if (this.timer === null) {
                return
            }
            window.clearTimeout(this.timer);
            this.timer = null
        },
        buttons: function(a, b) {
            if (a == null) {
                a = !this.locked && this.options.size !== 0 && ((this.options.wrap && this.options.wrap != "first") || this.options.size === null || this.last < this.options.size);
                if (!this.locked && (!this.options.wrap || this.options.wrap == "first") && this.options.size !== null && this.last >= this.options.size) {
                    a = this.tail !== null && !this.inTail
                }
            }
            if (b == null) {
                b = !this.locked && this.options.size !== 0 && ((this.options.wrap && this.options.wrap != "last") || this.first > 1);
                if (!this.locked && (!this.options.wrap || this.options.wrap == "last") && this.options.size !== null && this.first == 1) {
                    b = this.tail !== null && this.inTail
                }
            }
            var c = this;
            if (this.buttonNext.size() > 0) {
                this.buttonNext.unbind(this.options.buttonNextEvent + ".jcarousel", this.funcNext);
                if (a) {
                    this.buttonNext.bind(this.options.buttonNextEvent + ".jcarousel", this.funcNext)
                }
                this.buttonNext[a ? "removeClass" : "addClass"](this.className("jcarousel-next-disabled")).attr("disabled", a ? false : true);
                if (this.options.buttonNextCallback !== null && this.buttonNext.data("jcarouselstate") != a) {
                    this.buttonNext.each(function() {
                        c.options.buttonNextCallback(c, this, a)
                    }).data("jcarouselstate", a)
                }
            } else {
                if (this.options.buttonNextCallback !== null && this.buttonNextState != a) {
                    this.options.buttonNextCallback(c, null, a)
                }
            }
            if (this.buttonPrev.size() > 0) {
                this.buttonPrev.unbind(this.options.buttonPrevEvent + ".jcarousel", this.funcPrev);
                if (b) {
                    this.buttonPrev.bind(this.options.buttonPrevEvent + ".jcarousel", this.funcPrev)
                }
                this.buttonPrev[b ? "removeClass" : "addClass"](this.className("jcarousel-prev-disabled")).attr("disabled", b ? false : true);
                if (this.options.buttonPrevCallback !== null && this.buttonPrev.data("jcarouselstate") != b) {
                    this.buttonPrev.each(function() {
                        c.options.buttonPrevCallback(c, this, b)
                    }).data("jcarouselstate", b)
                }
            } else {
                if (this.options.buttonPrevCallback !== null && this.buttonPrevState != b) {
                    this.options.buttonPrevCallback(c, null, b)
                }
            }
            this.buttonNextState = a;
            this.buttonPrevState = b
        },
        notify: function(b) {
            var a = this.prevFirst === null ? "init" : (this.prevFirst < this.first ? "next" : "prev");
            this.callback("itemLoadCallback", b, a);
            if (this.prevFirst !== this.first) {
                this.callback("itemFirstInCallback", b, a, this.first);
                this.callback("itemFirstOutCallback", b, a, this.prevFirst)
            }
            if (this.prevLast !== this.last) {
                this.callback("itemLastInCallback", b, a, this.last);
                this.callback("itemLastOutCallback", b, a, this.prevLast)
            }
            this.callback("itemVisibleInCallback", b, a, this.first, this.last, this.prevFirst, this.prevLast);
            this.callback("itemVisibleOutCallback", b, a, this.prevFirst, this.prevLast, this.first, this.last)
        },
        callback: function(r, d, v, q, s, t, u) {
            if (this.options[r] == null || (typeof this.options[r] != "object" && d != "onAfterAnimation")) {
                return
            }
            var c = typeof this.options[r] == "object" ? this.options[r][d] : this.options[r];
            if (!h.isFunction(c)) {
                return
            }
            var b = this;
            if (q === undefined) {
                c(b, v, d)
            } else {
                if (s === undefined) {
                    this.get(q).each(function() {
                        c(b, this, q, v, d)
                    })
                } else {
                    var a = function(j) {
                        b.get(j).each(function() {
                            c(b, this, j, v, d)
                        })
                    };
                    for (var i = q; i <= s; i++) {
                        if (i !== null && !(i >= t && i <= u)) {
                            a(i)
                        }
                    }
                }
            }
        },
        create: function(a) {
            return this.format("<li></li>", a)
        },
        format: function(a, b) {
            a = h(a);
            var c = a.get(0).className.split(" ");
            for (var d = 0; d < c.length; d++) {
                if (c[d].indexOf("jcarousel-") != -1) {
                    a.removeClass(c[d])
                }
            }
            a.addClass(this.className("jcarousel-item")).addClass(this.className("jcarousel-item-" + b)).css({
                "float": (this.options.rtl ? "right" : "left"),
                "list-style": "none"
            }).attr("jcarouselindex", b);
            return a
        },
        className: function(a) {
            return a + " " + a + (!this.options.vertical ? "-horizontal" : "-vertical")
        },
        dimension: function(b, a) {
            var c = h(b);
            if (a == null) {
                return !this.options.vertical ? ((c.innerWidth() + f.intval(c.css("margin-left")) + f.intval(c.css("margin-right")) + f.intval(c.css("border-left-width")) + f.intval(c.css("border-right-width"))) || f.intval(this.options.itemFallbackDimension)) : ((c.innerHeight() + f.intval(c.css("margin-top")) + f.intval(c.css("margin-bottom")) + f.intval(c.css("border-top-width")) + f.intval(c.css("border-bottom-width"))) || f.intval(this.options.itemFallbackDimension))
            } else {
                var d = !this.options.vertical ? a - f.intval(c.css("marginLeft")) - f.intval(c.css("marginRight")) : a - f.intval(c.css("marginTop")) - f.intval(c.css("marginBottom"));
                h(c).css(this.wh, d + "px");
                return this.dimension(c)
            }
        },
        clipping: function() {
            return !this.options.vertical ? this.clip[0].offsetWidth - f.intval(this.clip.css("borderLeftWidth")) - f.intval(this.clip.css("borderRightWidth")) : this.clip[0].offsetHeight - f.intval(this.clip.css("borderTopWidth")) - f.intval(this.clip.css("borderBottomWidth"))
        },
        index: function(b, a) {
            if (a == null) {
                a = this.options.size
            }
            return Math.round((((b - 1) / a) - Math.floor((b - 1) / a)) * a) + 1
        }
    });
    f.extend({
        defaults: function(a) {
            return h.extend(g, a || {})
        },
        intval: function(a) {
            a = parseInt(a, 10);
            return isNaN(a) ? 0 : a
        },
        windowLoaded: function() {
            e = true
        },
        isSafari: function() {
            var a = navigator.userAgent.toLowerCase(),
                b = /(chrome)[ \/]([\w.]+)/.exec(a) || /(webkit)[ \/]([\w.]+)/.exec(a) || [],
                c = b[1] || "";
            return c === "webkit"
        }
    });
    h.fn.jcarousel = function(a) {
        if (typeof a == "string") {
            var c = h(this).data("jcarousel"),
                b = Array.prototype.slice.call(arguments, 1);
            return c[a].apply(c, b)
        } else {
            return this.each(function() {
                var d = h(this).data("jcarousel");
                if (d) {
                    if (a) {
                        h.extend(d.options, a)
                    }
                    d.reload()
                } else {
                    h(this).data("jcarousel", new f(this, a))
                }
            })
        }
    }
})(jQuery);