$(document).ready(function () {
	$(".fancybox").fancybox({
		autoResize: true,
		autoCenter: true,
		prevEffect: 'none',
		nextEffect: 'none',
		helpers: {
			title: {
				type: 'outside'
			},
			thumbs: {
				width: 50,
				height: 50
			},
			overlay: {
				locked: false
			}
		}
	});

	$(document).on('click', '.js-nav-pills-alt a', function(e) {
	    $(this).addClass('current').closest('li').addClass('current').siblings('.current').removeClass('current').find('a').removeClass('current');
    });
	
	if (mc_module_module == 'ProductsController') {
		var sort_filter_div = document.querySelector('#sortview .sort-wrapper');
		var sort_filter_div_position = sort_filter_div.getBoundingClientRect().top + window.pageYOffset;

		window.addEventListener('scroll', function() {
		  if (window.pageYOffset > sort_filter_div_position) {
			sort_filter_div.classList.add('sticky');
		  } else {
			sort_filter_div.classList.remove('sticky');
		  }
		});
	}
});